.mbsc-mcl.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-mcl.mbsc-schedule-wrapper {
  background: white;
  color: var(--gray-500);
}

.mbsc-calendar-header {
  z-index: 5;
}

.mbsc-schedule-header {
  background: var(--gray-100);
}
.mbsc-mcl.mbsc-schedule-date-header {
  display: none;
}

.mbsc-mcl.mbsc-eventcalendar .mbsc-calendar-header .p-multiselect-label {
  color: var(--text-color-secondary);
}

.mbsc-ios.mbsc-textfield-outline {
  border-radius: var(--border-radius);
}

.mbsc-ios.mbsc-textfield-wrapper-outline {
  margin: 0;
}

.mbsc-ios.mbsc-textfield {
  height: 3.1em;
}

.mbsc-ios.mbsc-select-icon {
  color: var(--text-color-secondary);
  top: 0.9em;
}

.mbsc-icon {
  width: 1.35em;
}

.mbsc-readonly-event {
  opacity: 0.5;
}

.mbsc-eventcalendar {
  height: 100vh;
}

.mbsc-schedule-all-day-wrapper-shadow:after {
  z-index: 1;
}

.mbsc-mcl.mbsc-calendar-button.mbsc-button {
  color: var(--secondary-color);
}

.mbsc-mcl.mbsc-schedule-header-day.mbsc-selected {
  background: var(--secondary-color);
}

.mbsc-popup-wrapper {
  z-index: 20;
}

.mbsc-schedule-resource {
  align-self: center;
}

.mbsc-hidden-content {
  display: none;
}