@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/gwv1zkw.css");

@layer base {
  h1 {
    @apply text-4xl;
    @apply uppercase;
    @apply leading-tight;
  }

  html {
    font-family: proxima-nova, system-ui, sans-serif;
  }
  body,
  #root {
    @apply min-h-screen;
  }
  h1, h2 {
    font-family: futura-pt, system-ui, sans-serif;
  }

  .tooltip-button {
    border: none;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
  }

  /* Essential override that will minimize animations when a user has requested this behavior */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-play-state: paused !important;
      transition: none !important;
      scroll-behavior: auto !important;
    }
  }
}
